.Board {
  background: black;
}

.Board .row {
  display: flex;
}

.Board .cell {
  --side: calc((100vmin - 2px) / 24);
  --border: calc(var(--side) / 10);
  --hue: calc(19 * var(--idx) * 12deg);

  --sat: 100%;

  width: var(--side);
  height: var(--side);

  border-left: var(--border) solid hsl(var(--hue), var(--sat), 70%);
  border-top: var(--border) solid hsl(var(--hue), var(--sat), 70%);
  border-bottom: var(--border) solid hsl(var(--hue), var(--sat), 30%);
  border-right: var(--border) solid hsl(var(--hue), var(--sat), 30%);
  background: hsl(var(--hue), var(--sat), 50%);
}

.Board .cell.empty {
}
.Board .cell.color-0 {
  --idx: 0;
}
.Board .cell.color-1 {
  --idx: 1;
}
.Board .cell.color-2 {
  --idx: 2;
}
.Board .cell.color-3 {
  --idx: 3;
}
.Board .cell.color-4 {
  --idx: 4;
}
.Board .cell.color-5 {
  --idx: 5;
}
.Board .cell.color-6 {
  --idx: 6;
}
.Board .cell.color-7 {
  --idx: 7;
}
.Board .cell.color-8 {
  --idx: 8;
}
.Board .cell.color-9 {
  --idx: 9;
}
.Board .cell.color-10 {
  --idx: 10;
}
.Board .cell.color-11 {
  --idx: 11;
}
.Board .cell.color-12 {
  --idx: 12;
}
.Board .cell.color-13 {
  --idx: 13;
}
.Board .cell.color-14 {
  --idx: 14;
}
.Board .cell.color-15 {
  --idx: 15;
}
.Board .cell.color-16 {
  --idx: 16;
}
.Board .cell.color-17 {
  --idx: 17;
}
.Board .cell.color-18 {
  --idx: 18;
}
.Board .cell.color-19 {
  --idx: 19;
}
.Board .cell.color-20 {
  --idx: 20;
}
.Board .cell.color-21 {
  --idx: 21;
}
.Board .cell.color-22 {
  --idx: 22;
}
.Board .cell.color-23 {
  --idx: 23;
}
.Board .cell.color-24 {
  --idx: 24;
}
.Board .cell.color-25 {
  --idx: 25;
}
.Board .cell.color-26 {
  --idx: 26;
}
.Board .cell.color-27 {
  --idx: 27;
}
.Board .cell.color-28 {
  --idx: 28;
}
.Board .cell.color-29 {
  --idx: 29;
}
