.container {
  width: 100vmin;
  height: 100vmin;
  position: relative;
  border: 1px solid black;
}

h1 {
  font-size: 3em;
  line-height: 2em;
  margin: 0;
}
